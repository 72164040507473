import React from "react";

export default function Cimg() {
  return (
    <>
      <div className="c">
        <img
          src="	https://nav.porsche.com/00BC524/series-assets/1360/718.webp"
          alt=""
        />
        <img
          src="https://nav.porsche.com/00BC524/series-assets/1360/911.webp"
          alt=""
        />
        <img
          src="	https://nav.porsche.com/00BC524/series-assets/1360/taycan.webp"
          alt=""
        />
        <img
          src="	https://nav.porsche.com/00BC524/series-assets/1360/panamera.webp"
          alt=""
        />
        <img
          src="	https://nav.porsche.com/00BC524/series-assets/1360/macan.webp"
          alt=""
        />
        <img
          src="	https://nav.porsche.com/00BC524/series-assets/1360/cayenne.webp"
          alt=""
        />
      </div>
    </>
  );
}
