import React, { useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Carcarousel.css";

const Carcarousel = () => {
  const [startIndex, setStartIndex] = useState(0);

  const images = [
    {
      src: "https://files.porsche.com/filestore/image/multimedia/none/homepage-teaser-icc-718/normal/db4be0ec-f8f4-11eb-80db-005056bbdc38;sP;twebp/porsche-normal.webp",
      buttonText: "Find 718 Models",
    },
    {
      src: "https://files.porsche.com/filestore/image/multimedia/none/homepage-teaser-icc-911/normal/e7c2070d-f8f4-11eb-80db-005056bbdc38;sP;twebp/porsche-normal.webp",
      buttonText: "Find 911 Models",
    },
    {
      src: "https://files.porsche.com/filestore/image/multimedia/none/homepage-teaser-icc-taycan/normal/253ef544-f8f5-11eb-80db-005056bbdc38;sP;twebp/porsche-normal.webp",
      buttonText: "Find Taycan Models",
    },
    {
      src: "https://files.porsche.com/filestore/image/multimedia/none/homepage-teaser-icc-panamera/normal/19092f4b-f8f5-11eb-80db-005056bbdc38;sP;twebp/porsche-normal.webp",
      buttonText: "Find Panamera Models",
    },
    {
      src: "https://files.porsche.com/filestore/image/multimedia/none/homepage-teaser-icc-macan/normal/0355d78f-f8f5-11eb-80db-005056bbdc38;sP;twebp/porsche-normal.webp",
      buttonText: "Find Macan Models",
    },
    {
      src: "https://files.porsche.com/filestore/image/multimedia/none/homepage-teaser-icc-cayenne-e3/normal/ef4d48a8-f8f4-11eb-80db-005056bbdc38;sP;twebp/porsche-normal.webp",
      buttonText: "Find Cayenne Models",
    },
    // Add more images as needed
  ];

  const nextSet = () => {
    const newIndex = (startIndex + 1) % images.length;
    setStartIndex(newIndex);
  };

  const prevSet = () => {
    let newIndex = startIndex - 1;
    if (newIndex < 0) {
      newIndex = 0;
    }
    setStartIndex(newIndex);
  };

  return (
    <div className="carcarousel-container">
      <div className="carcarousel-button-container">
        {/* eslint-disable-next-line */}
        <a
          className={`carcarousel-button carcarousel-button-prev${
            startIndex === 0 ? " disabled" : ""
          }`}
          onClick={prevSet}
        >
          &#8249;
        </a>
        {/* eslint-disable-next-line */}
        <a
          className={`carcarousel-button carcarousel-button-next${
            startIndex === images.length - 3 ? " disabled" : ""
          }`}
          onClick={nextSet}
        >
          &#8250;
        </a>
      </div>
      <div className="carcarousel-inner">
        <div
          className="carcarousel-slider"
          style={{ transform: `translateX(-${startIndex * 17}%)` }}
        >
          {images.map((item, i) => (
            <div key={i} className="carcarousel-item">
              <img src={process.env.PUBLIC_URL + item.src} alt={`${i + 1}`} />
              <button className="carcarousel-button"><span style={{fontSize:"3rem"}}>&#8250;</span> {item.buttonText}</button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Carcarousel;
