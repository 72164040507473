import React from "react";
import "./St.css"

const ShoppingTools = () => {
  return (
    <div className="parent">
      <div className="image-container div1 larger">
        <div className="overlay">
          <a className="link"><span>&#8250;</span>Compare</a>
        </div>
      </div>
      <div className="image-container div2">
        <div className="overlay">
          <a className="link"><span>&#8250;</span>Locate a Dealer</a>
        </div>
      </div>
      <div className="image-container div3">
        <div className="overlay">
          <a className="link"><span>&#8250;</span>Porsche Lifestyle</a>
        </div>
      </div>
      <div className="image-container div4">
        <div className="overlay">
          <a className="link"><span>&#8250;</span>Contact Us</a>
        </div>
      </div>
    </div>
  );
};

export default ShoppingTools;
