import { useState } from "react";
import "./App.css";
import Caraousel from "./components/Caraousel";
import Grids from "./components/Grids";
import Navbar from "./components/Navbar";
import Carcarousel from "./components/Carcarousel";
// eslint-disable-next-line
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ShoppingTools from "./components/ShoppingTools";
import Discover from "./components/Discover";
import Footer from "./components/Footer";
import Legal from "./components/Legal";
// import logo from "./logo.png";

function App() {
  const [hidden, setHidden] = useState(false);
  const toggleHide = () => {
    setHidden(!hidden);
  };

  const images = [
    {
      src: "https://files.porsche.com/filestore/image/multimedia/none/homepage-teaser-icc-718/normal/db4be0ec-f8f4-11eb-80db-005056bbdc38;sP;twebp/porsche-normal.webp",
      buttonText: "Button 1",
    },
    { src: "image2.jpg", buttonText: "Button 2" },
    { src: "image3.jpg", buttonText: "Button 3" },
    // Add more images as needed
  ];

  return (
    <>
      <BrowserRouter>
        <Navbar toggleHide={toggleHide} hidden={hidden} />
        <Caraousel />
        {!hidden && (
          <h2
            style={{ marginTop: "5rem", fontSize: "3rem", marginLeft: "5rem" }}
          >
            Models
          </h2>
        )}
        {!hidden && <Grids toggleHide={toggleHide} hidden={hidden} />}
        {!hidden && (
          <h2
            style={{ marginTop: "5rem", fontSize: "3rem", marginLeft: "5rem" }}
          >
            Find a Porsche near you
          </h2>
        )}
        {!hidden && <Carcarousel items={images} />}

        {!hidden && (
          <h2
            style={{ marginTop: "5rem", fontSize: "3rem", marginLeft: "5rem" }}
          >
            Shopping Tools
          </h2>
        )}

        {!hidden && <ShoppingTools />}
        {!hidden && (
          <h2
            style={{ marginTop: "5rem", fontSize: "3rem", marginLeft: "5rem" }}
          >
            Discover
          </h2>
        )}

        {!hidden && <Discover />}
        {!hidden && <Footer />}
        {!hidden && <Legal />}
      </BrowserRouter>
    </>
  );
}

export default App;
