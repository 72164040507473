import React, { useState } from "react";
import ra from "../ra.png";
import { Link } from "react-router-dom";
import Cimg from "./Cimg";
import Vp from "./Vp";
import "./Menu.css";
import Services from "./Services";
import Experience from "./Experience";
import FindADealer from "./FindADealer";

function Menu({ toggleHide, hidden }) {
  const [opened, setOpened] = useState(false);
  const [activeButton, setActiveButton] = useState("s1");
  const [componentsToShow, setComponentsToShow] = useState("Cimg"); // Default component to show

  const toggleMenu = () => {
    setOpened(!opened);
    // console.log(opened)

    console.log("clicked");
    toggleHide();
    styleChanger();
  };

  const styleChanger = () => {
    const menu_button = document.getElementById("menu");
    let sidebar = document.getElementById("sidebar");
    if ((menu_button.style.display = "hidden")) {
      sidebar.style.display = "flex";
      // sidebar.
    } else {
      menu_button.style.display = "flex";
      sidebar.style.display = "hidden";
    }
  };

  const buttons = [
    { id: "s1", label: "Models" },
    { id: "s2", label: "Vehicle Purchase" },
    { id: "s3", label: "Services" },
    { id: "s4", label: "Experience" },
    { id: "s5", label: "Find a dealer" },
  ];

  const handleButtonClick = (id) => {
    setActiveButton(id);
    switch (id) {
      case "s1":
        setComponentsToShow("Cimg");
        break;
      case "s2":
        setComponentsToShow("Vp");
        break;
      case "s3":
        setComponentsToShow("Services");
        break;
      case "s4":
        setComponentsToShow("Experience");
        break;
      case "s5":
        setComponentsToShow("FindADealer");
        break;
      default:
        setComponentsToShow("Cimg"); // Default to "Cimg" for any other button
    }
  };

  return (
    <>
      <div
        className={`menu ${opened ? "opened" : ""}`}
        onClick={toggleMenu}
        aria-label="Main Menu"
        id="menu"
      >
        <svg width="40" height="40" viewBox="0 0 100 100">
          <path
            className="line line1"
            d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
          />
          <path className="line line2" d="M 20,50 H 80" />
          <path
            className="line line3"
            d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
          />
        </svg>
        <span className="mm">Menu</span>
      </div>
      <div className={`sidebar ${opened ? "visible" : ""}`} id="sidebar">
        <div className="txt">
          {buttons.map((button) => (
            <button
              key={button.id}
              className={`side-items ${
                button.id === activeButton ? "active" : ""
              }`}
              onClick={() => handleButtonClick(button.id)}
            >
              <Link to="/">
                {button.label} <img src={ra} alt="" />
              </Link>
            </button>
          ))}
        </div>
        <div className="images">
          <div className="component-container">
            {componentsToShow === "Cimg" && <Cimg/>}
            {componentsToShow === "Vp" && <Vp />}
            {componentsToShow === "Services" && <Services />}
            {componentsToShow === "Experience" && <Experience />}
            {componentsToShow === "FindADealer" && <FindADealer />}
          </div>
        </div>
        <div className="close">
          <button
            type="button"
            className="btn-close"
            onClick={toggleMenu}
            aria-label="Close"
          ></button>
        </div>
      </div>
    </>
  );
}

export default Menu;
