import React from "react";

export default function Services() {
  return (
    <div
      className="c c2"
      style={{ justifyContent: "flex-start", marginLeft: "0" }}
    >
      <h3>Factory Delivery</h3>
      <h3>Tequipment – Genuine Accessories</h3>
      <h3>Exclusive Manufaktur – Individual Vehicles</h3>
      <h3>Approved – Pre-owned Cars & Warranty</h3>
      <h3>Service & Maintenance</h3>
      <h3>Classic – Service & Parts</h3>
      <h3>Lifestyle – Fashion & Accessories</h3>
    </div>
  );
}
