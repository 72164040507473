import React from "react";
import "./discover.css";
import ointl from "../ointl.png";

const Discover = () => {
  return (
    <div className="disdiv">
      <div className="discontainer">
        <div className="childimg">
          <div className="img-con">
            <img
              src="https://files.porsche.com/filestore/image/multimedia/none/rd-2018-homepage-teaser-ww-e-performance-kw12/normal/f7cc8e7c-6599-11e9-80c4-005056bbdc38;sL;twebp/porsche-normal.webp"
              alt=""
            />
          </div>
          <div className="btext">
            <div className="spntxt">
              <span>&#8250;</span>
              <p>Porsche E-Performance</p>
            </div>
          </div>
        </div>
        <div className="childimg">
          <div className="img-con">
            <img
              src="https://files.porsche.com/filestore/image/multimedia/none/rd-2019-homepage-teaser-pof-porschefinder-kw45/normal/f26ec9e6-fb09-11e9-80c6-005056bbdc38;sL;twebp/porsche-normal.webp"
              alt=""
            />
          </div>
          <div className="btext">
            <div className="spntxt">
            {/* <span>&#8250;</span> */}
            <img src={ointl} alt="" style={{width: "15px", paddingTop:"10px"}}/>
              <p>Porsche Finder</p>
            </div>
          </div>
        </div>
        <div className="childimg">
          <div className="img-con">
            <img
              src="https://files.porsche.com/filestore/image/multimedia/none/rd-2022-homepage-teaser-ww-pds-porschelifestyle-kw22/normal/585df047-db34-11ec-80ef-005056bbdc38;sL;twebp/porsche-normal.webp"
              alt=""
            />
          </div>
          <div className="btext">
            <div className="spntxt">
              <span>&#8250;</span>
              <p>Porsche Lifestyle</p>
            </div>
          </div>
        </div>
        <div className="childimg">
          <div className="img-con">
            <img
              src="https://files.porsche.com/filestore/image/multimedia/none/rd-2017-homepage-teaser-porscheexclusivemanufaktur-kw24/normal/3cbd9b47-4c59-11e7-bfe2-0019999cd470;sL;twebp/porsche-normal.webp"
              alt=""
            />
          </div>
          <div className="btext">
            <div className="spntxt" style={{marginRight: "2rem"}}>
              <span>&#8250;</span>
              <p>Porsche Exclusive Manufaktur</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Discover;
