import React from "react";

export default function Experience() {
  return (
    <div
      className="c c2"
      style={{ justifyContent: "flex-start", marginLeft: "1rem" }}
    >
      <h3>Motorsport</h3>
      <h3>Porsche Experience – Driving & Track Experiences</h3>
      <h3>Porsche Clubs</h3>
      <h3>Golf</h3>
      <h3>Christophorus – The Porsche Magazine</h3>
      <h3>Porsche Museum</h3>
    </div>
  );
}
