import React from "react";
import "./Footer.css";
import share from "../share.png";
import fb from "../fb.png";
import yt from "../yt (1).png";
import pinterest from "../pinterest-48.png";
import ig from "../ig.png";
import linkedin from "../linkedin.png";

const Footer = () => {
  return (
    <>
      <hr style={{ width: "90vw", marginLeft: "5vw" }} />
      <div className="footer">
        <div className="footer">
          <div className="coll">
            <h4>Build & Find</h4>
            <div className="footitems">
              <ul>
                <li className="footlink">
                  <a href="/">Build your Porsche</a>
                </li>
                <li className="footlink">
                  <a href="/">Compare Models</a>
                </li>
                <li className="footlink">
                  <a href="/">Search Pre-Owned</a>
                </li>
                <li className="footlink">
                  <a href="/">Find your Porsche Centre</a>
                </li>
                <li className="footlink">
                  <a href="/">Download the model range catalogue</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="coll">
            <h4>Online Services</h4>
            <div className="footitems">
              <ul>
                <li className="footlink">
                  <a href="/">Porsche Login</a>
                </li>
                <li className="footlink">
                  <a href="/">Sign up for News</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="coll">
            <h4>Behind the Scenes</h4>
            <div className="footitems">
              <ul>
                <li className="footlink">
                  <a href="/">Passion Motorsports</a>
                </li>
                <li className="footlink">
                  <a href="/">Porsche Experience</a>
                </li>
                <li className="footlink">
                  <a href="/">Porsche Classic</a>
                </li>
                <li className="footlink">
                  <a href="/">Visit Porsche Museum</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="coll">
            <h4>Porsche Company</h4>
            <div className="footitems">
              <ul>
                <li className="footlink">
                  <a href="/">Latest News</a>
                </li>
                <li className="footlink">
                  <a href="/">At a Glance</a>
                </li>
                <li className="footlink">
                  <a href="/">Jobs & Careers</a>
                </li>
                <li className="footlink">
                  <a href="/">Contact & Information</a>
                </li>
                <li className="footlink">
                  <a href="/">Christophorus - The Porsche Magazine</a>
                </li>
                <li className="footlink spbtn">
                  <button className="sp">
                    <span>
                      <img src={share} alt="" />
                    </span>
                    Share Page
                  </button>
                </li>
                <li className="footlink">Connect with Porsche</li>
                <li className="footlink">
                  <a href="/">
                    <img src={fb} alt="" />
                  </a>
                  <a href="/">
                    <img
                      src={yt}
                      alt=""
                      style={{ marginLeft: "5px", width: "58px" }}
                    />
                  </a>
                  <a href="/">
                    <img src={pinterest} alt="" style={{ marginLeft: "5px" }} />
                  </a>
                  <a href="/">
                    <img src={ig} alt="" style={{ marginLeft: "5px" }} />
                  </a>
                  <a href="/">
                    <img src={linkedin} alt="" style={{ marginLeft: "5px" }} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
