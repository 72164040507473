import React, { useState, useRef, useEffect } from "react";
import "./Legal.css";

const Legal = () => {
  const [isFirstDropdownOpen, setIsFirstDropdownOpen] = useState(false);
  const [isSecondDropdownOpen, setIsSecondDropdownOpen] = useState(false);
  const [firstDropdownValue, setFirstDropdownValue] = useState("");
  const [secondDropdownValue, setSecondDropdownValue] = useState("");
  const [isSecondEnabled, setIsSecondEnabled] = useState(false);
  const [isSecondDisabled, setIsSecondDisabled] = useState(true);

  const firstDropdownRef = useRef(null);
  const secondDropdownRef = useRef(null);
  const firstButtonRef = useRef(null);
  const secondButtonRef = useRef(null);

  const handleFirstDrp = () => {
    setIsFirstDropdownOpen(!isFirstDropdownOpen);
    if (isSecondDropdownOpen && !isFirstDropdownOpen) {
      setIsSecondDropdownOpen(false);
    }
  };

  const handleSecondDrp = () => {
    if (isSecondEnabled) {
      setIsSecondDropdownOpen(!isSecondDropdownOpen);
    }
  };

  const handleFirstSelection = (value) => {
    setFirstDropdownValue(value);
    setIsSecondEnabled(true);
    setIsSecondDisabled(false);
    setIsFirstDropdownOpen(false);
    setIsSecondDropdownOpen(true);
    if (secondDropdownValue && value !== firstDropdownValue) {
      setSecondDropdownValue("");
      setIsSecondDisabled(true);
    }
  };

  const handleSecondSelection = (value) => {
    setSecondDropdownValue(value);
    setIsSecondDropdownOpen(false);
  };

  const handleClick = (event) => {
    if (
      firstDropdownRef.current &&
      !firstDropdownRef.current.contains(event.target) &&
      firstButtonRef.current &&
      !firstButtonRef.current.contains(event.target)
    ) {
      setIsFirstDropdownOpen(false);
    }

    if (
      secondDropdownRef.current &&
      !secondDropdownRef.current.contains(event.target) &&
      secondButtonRef.current &&
      !secondButtonRef.current.contains(event.target)
    ) {
      if (!firstDropdownValue) {
        setIsSecondDropdownOpen(false);
      }
    }
  };

  useEffect(() => {
    if (isFirstDropdownOpen || isSecondDropdownOpen) {
      window.addEventListener("click", handleClick);
    } else {
      window.removeEventListener("click", handleClick);
    }
    if (!firstDropdownValue) {
      setIsSecondDisabled(true);
    }
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, [
    isFirstDropdownOpen,
    isSecondDropdownOpen,
    firstDropdownValue,
    secondDropdownValue,
  ]);

  return (
    <>
      <div className="last">
        <div className="legalup">
          <h3>Change country/region:</h3>
          <div className="btncon">
            <div className={`drps ${isFirstDropdownOpen ? "show" : ""}`}>
              <button onClick={handleFirstDrp} ref={firstButtonRef}>
                {firstDropdownValue || "Select a region"}
                <span>&#8250;</span>
              </button>
              <div ref={firstDropdownRef} className="dropdownContent">
                <button onClick={() => handleFirstSelection("Value 1")}>
                  Value 1
                </button>
                <button onClick={() => handleFirstSelection("Value 2")}>
                  Value 2
                </button>
                <button onClick={() => handleFirstSelection("Value 3")}>
                  Value 3
                </button>
              </div>
            </div>
            <div
              className={`drps ${
                isSecondDropdownOpen && isSecondEnabled ? "show" : ""
              } ${isSecondDisabled ? "grey" : ""}`}
            >
              <button onClick={handleSecondDrp} ref={secondButtonRef}>
                {secondDropdownValue || "Select a country/region"}
                <span>&#8250;</span>
              </button>
              <div ref={secondDropdownRef} className="dropdownContent">
                {firstDropdownValue && (
                  <>
                    <button
                      onClick={() => handleSecondSelection("Second Value 1")}
                    >
                      Second Value 1
                    </button>
                    <button
                      onClick={() => handleSecondSelection("Second Value 2")}
                    >
                      Second Value 2
                    </button>
                    <button
                      onClick={() => handleSecondSelection("Second Value 3")}
                    >
                      Second Value 3
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="legaldown">
          <hr style={{ width: "90vw", marginLeft: "5vw", color: "white" }} />
          <p>
            © 2023 Porsche India - SKODA AUTO Volkswagen India Private Limited -
            India.{" "}
            <span className="legallinks">Legal notice.</span>{" "}
            <span className="legallinks">Cookie & Privacy Policy.</span>{" "}
            <span className="legallinks">Corporate Governance.</span>{" "}
            <span className="legallinks">Open Source Software Notice.</span>{" "}
            <span className="legallinks">Whistleblower System.</span>{" "}
            <span className="legallinks">Business and Human Rights.</span>{" "}
          </p>
        </div>
      </div>
    </>
  );
};

export default Legal;
