import React from "react";
import "./Fad.css";

export default function FindADealer() {
  return (
    <>
      <div className="search-input">
        <input type="text" placeholder="Zip code or town" />
        <button type="submit" className="search-button">
          &#128269; {/* Unicode character for search icon */}
        </button>
      </div>
    </>
  );
}
