import React from "react";
import "./Vp.css";

export default function Vp() {
  return (
    <>
      {/* <div className="c"> */}
      <div className="c c2" style={{ justifyContent: "flex-start"}}>
        <h3>Configure</h3>
        <h3>Compare Models</h3>
        <h3>Find New and Pre-owned Vehicles</h3>
        <h3>Saved Vehicles</h3>
        <h3>E-Mobility & E-Performance</h3>
      </div>
    </>
  );
}
